import { useCallback, useMemo } from 'react';
import useSWR from 'swr';

import { ClientNetworkError } from '@/lib/errors/client';

import { tokensSelector, useTokenStore } from '../store/token';
import {
  TokenResult,
  TokensByAddressesQuery,
  TokensResponse,
} from '../utils/0x/token-registry.types';
import { routes } from '../utils/routes';
import { objectToQueryString } from '../utils/string';
import { fetcher, getFullURL, handleResponse } from '../utils/swr';

const TOKEN_ADDRESSES_LIMIT = 50;

interface TokenMap {
  [chainId: number]: {
    [address: string]: TokenResult;
  };
}

/**
 * Get token result for many tokens by contract address and chain ID
 * @param tokenAddresses - array of contract addresses
 * @param chainId - chain id
 * @returns TokenResult[]
 */
export default function useTokenResults(
  tokenAddresses: string[] | undefined,
  chainId: number | undefined,
): {
  isLoading: boolean;
  tokenMap: TokenMap | undefined;
} {
  const { tokens: allTokens, addTokens } = useTokenStore(tokensSelector);

  const tokens = useMemo(
    () =>
      tokenAddresses && chainId
        ? tokenAddresses.reduce(
            (obj, tokenAddress) => {
              if (!obj[chainId]) obj[chainId] = {};
              obj[chainId][tokenAddress.toLowerCase()] =
                allTokens[chainId]?.[tokenAddress.toLowerCase()]?.token;
              return obj;
            },
            {} as { [chainId: number]: { [contractAddress: string]: TokenResult } },
          )
        : undefined,
    [allTokens, tokenAddresses, chainId],
  );

  const params: TokensByAddressesQuery | undefined = useMemo(
    () =>
      tokenAddresses?.length && chainId
        ? { addresses: tokenAddresses, chainId: [chainId] }
        : undefined,
    [chainId, tokenAddresses],
  );

  const tokenResultsFetcher = useCallback(async () => {
    if (!tokenAddresses?.length) return Promise.resolve({ data: [], resultCount: 0 });

    const addresses = [...tokenAddresses];
    const addressesGroups = [];
    // To stay under the max request input limit for token API,
    // group the addresses in batches of TOKEN_ADDRESSES_LIMIT.
    while (addresses.length > 0) {
      addressesGroups.push(addresses.splice(0, TOKEN_ADDRESSES_LIMIT));
    }

    try {
      const responses = await Promise.all(
        addressesGroups.map((addresses: string[]) =>
          fetch(
            getFullURL(`${routes.api.SEARCH_TOKENS}${objectToQueryString({ addresses, chainId })}`),
          ).then(handleResponse<TokensResponse>),
        ),
      );

      const results = ([] as TokenResult[]).concat(
        ...responses.map((response) => ('data' in response ? response.data : [])),
      );

      return {
        data: results,
        resultCount: results.length,
      };
    } catch (err) {
      if (err instanceof Error) {
        throw err;
      }
    }

    throw new ClientNetworkError('Token results failed to fetch.');
  }, [chainId, tokenAddresses]);

  const { isLoading } = useSWR(
    params ? [`tokenResults-${tokenAddresses?.join('-')}-${chainId}`, params] : null,
    tokenResultsFetcher,
    {
      onSuccess: (tokenResults: TokensResponse) => {
        if ('data' in tokenResults && tokenResults.data) {
          // cache tokens
          addTokens(tokenResults.data);
        }
      },
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  return { isLoading, tokenMap: tokens };
}

/**
 * Get token results for many tokens by contract address across multiple chain IDs
 * @param tokenAddresses - array of contract addresses
 * @param chainIds - array of chain ids
 * @returns TokenResult[]
 */
export function useTokenResultsMultiChain(
  tokenAddresses: string[] | undefined,
  chainIds: number[] | undefined,
): {
  isLoading: boolean;
  tokenMap: TokenMap | undefined;
} {
  const { tokens: allTokens, addTokens } = useTokenStore(tokensSelector);

  const tokens = useMemo(
    () =>
      tokenAddresses && chainIds
        ? chainIds.reduce((chainObj, chainId) => {
            chainObj[chainId] = tokenAddresses.reduce(
              (tokenObj, tokenAddress) => {
                tokenObj[tokenAddress.toLowerCase()] =
                  allTokens[chainId]?.[tokenAddress.toLowerCase()]?.token ??
                  allTokens[chainId]?.[tokenAddress]?.token;
                return tokenObj;
              },
              {} as { [contractAddress: string]: TokenResult },
            );
            return chainObj;
          }, {} as TokenMap)
        : undefined,
    [allTokens, tokenAddresses, chainIds],
  );

  const params: TokensByAddressesQuery | undefined = useMemo(
    () =>
      tokenAddresses?.length && chainIds?.length
        ? { addresses: tokenAddresses, chainId: chainIds }
        : undefined,
    [chainIds, tokenAddresses],
  );

  const url = useMemo(
    () => (params ? `${routes.api.SEARCH_TOKENS}${objectToQueryString(params)}` : undefined),
    [params],
  );

  const { isLoading } = useSWR(url ?? null, fetcher, {
    onSuccess: (tokenResults: TokensResponse) => {
      if ('data' in tokenResults && tokenResults.data) {
        // cache tokens
        addTokens(tokenResults.data);
      }
    },
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return { isLoading, tokenMap: tokens };
}

import * as Dialog from '@radix-ui/react-dialog';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import React, {
  Key,
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Chain } from 'viem';

import { SOLANA_PHASE1_COOKIE_KEY, useFeature } from '@/hooks/useFeature';
import { useMatchaWallets } from '@/hooks/useMatchaWallets';

import { recentlyViewedTokensSelectors, useSearchStore } from '@/store/search';

import { isChainAddress } from '@/utils/models';

import {
  solana,
  CHAIN_IDS,
  SUPPORTED_CHAIN_IDS,
  SUPPORTED_CHAIN_IDS_V2,
} from '../../../constants/chain';
import { NETWORK_NAME_FOR_URLPATH_PER_CHAIN_ID } from '../../../constants/chain';
import { useDebounce } from '../../../hooks/useDebounce';
import { useSearchTokens } from '../../../hooks/useSearchTokens';
import useTokensWithBalances from '../../../hooks/useTokensWithBalances';
import { useTrendingTokens } from '../../../hooks/useTrendingTokens';
import {
  dialogCloseBtnClass,
  dialogCloseBtnIconClass,
  dialogOverlayClass,
} from '../../../styles/dialog.css';
import { fontStyles } from '../../../styles/typography.css';
import { SearchIcon, XIcon } from '../../../ui/Icons';
import { TokenResult, TokensResponse } from '../../../utils/0x/token-registry.types';
// utils
import { EVENT_NAME, SEARCH_TYPES, logEvent } from '../../../utils/amplitude';
import { joinSearchResults } from '../../../utils/search';
import { contentContainerClass, dialogClass, searchIconClass } from './index.css';
import {
  getSections,
  SEARCH_SECTION_ID,
  SEARCH_SECTION_LOAD_MORE_PREFIX,
  SEARCH_SECTION_TYPE_BALANCES,
  SEARCH_SECTION_TYPE_RECENTLY_VIEWED,
  SEARCH_SECTION_TYPE_RESULTS,
  SEARCH_SECTION_TYPE_TRENDING_TOKENS,
} from './SearchComboBox/getSections';
import { TokenSelectModalComboBox } from './TokenSelectModalComboBox';
import { getSearchComboBoxItemPropsForTokens } from './utils';

const TRENDING_TOKENS_DEFAULT_COUNT = 10;
const TOKENS_WITH_BALANCE_DEFAULT_COUNT = 5;

const defaultPaginatedResults: TokensResponse[] = [];

interface ContentProps {
  /** The chain ID to filter tokens by */
  chainId?: number;
  /** Callback when a token is selected */
  onSelect: (token: TokenResult) => void;
  /** The active token */
  activeToken?: TokenResult;
  /** The supported chains */
  supportedChains?: Chain[];
  /** Component that triggered the token select modal */
  searchType: SEARCH_TYPES;
}

const Content = ({ chainId, onSelect, supportedChains, searchType }: ContentProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { solanaWallet, ethereumWallet } = useMatchaWallets();
  const solanaWalletAddress = solanaWallet ? solanaWallet.address : undefined;
  const isSolanaPhase1 = useFeature(SOLANA_PHASE1_COOKIE_KEY);
  const [selectedChainIdFilter, setSelectedChainIdFilter] = useState(chainId);
  const userAddress =
    selectedChainIdFilter === solana.id ? solanaWalletAddress : ethereumWallet?.address;
  const [shouldShowAllTokensWithBalances, setShouldShowAllTokensWithBalances] = useState(false);
  const [shouldShowAllTrendingTokens, setShouldShowAllTrendingTokens] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const debouncedInputValue = useDebounce(inputValue, 300);
  const suggestedTokensChainId =
    selectedChainIdFilter ?? supportedChains?.[0]?.id ?? CHAIN_IDS.MAINNET;
  const supportedChainIds = isSolanaPhase1 ? SUPPORTED_CHAIN_IDS_V2 : SUPPORTED_CHAIN_IDS;
  const chainIds = useMemo(
    () =>
      selectedChainIdFilter
        ? [selectedChainIdFilter]
        : supportedChains
          ? supportedChains.map((chain: Chain) => chain.id)
          : supportedChainIds,
    [selectedChainIdFilter, supportedChains, supportedChainIds],
  );

  // search for tokens if there is an input value (search query)
  const {
    data: searchResultsPaginated,
    isLoading: isLoadingSearchResults,
    isValidating: isValidatingSearch,
    error: searchError,
    size: searchSize,
    setSize: setSearchSize,
  } = useSearchTokens({
    address: isChainAddress(debouncedInputValue) ? debouncedInputValue : undefined,
    chainIds,
    limit: 50,
    query: isChainAddress(debouncedInputValue) ? undefined : debouncedInputValue,
  });

  // Get all paginated results from 2d array into a single list
  const searchResults = useMemo(
    () => (searchResultsPaginated ? joinSearchResults(searchResultsPaginated) : undefined),
    [searchResultsPaginated],
  );

  /**
   * Effect to track search initiated event.
   */
  useEffect(() => {
    if (searchResults && debouncedInputValue) {
      logEvent({
        name: EVENT_NAME.SEARCH_INITIATED,
        properties: {
          endpoint: isChainAddress(debouncedInputValue) ? `/address` : `/search`,
          input_query: debouncedInputValue,
          search_results: searchResults.length ? 'successful' : 'none',
          search_type: searchType,
        },
      });
    }
  }, [debouncedInputValue, searchResults, searchType]);

  // trending tokens are included in the default state
  const {
    data: trendingTokens,
    isLoading: isLoadingTrendingResults,
    error: trendingResultsError,
  } = useTrendingTokens({
    chainIds,
    limit: 30,
  });

  // fetch user's tokens with non-zero balances
  const tokensWithBalances = useTokensWithBalances(
    userAddress && isChainAddress(userAddress) ? userAddress : undefined,
    selectedChainIdFilter,
  );

  // recently searched tokens
  const { recentlyViewed, addRecentlyViewedToken, clearRecentlyViewedTokens } = useSearchStore(
    recentlyViewedTokensSelectors,
  );

  // memoize the token lists
  const searchResultsItems = useMemo(
    () => getSearchComboBoxItemPropsForTokens(searchResults),
    [searchResults],
  );

  const recentResultsItems = useMemo(() => {
    // If we're on the search results page, isolate recent results from the search results
    const searchResults = searchResultsPaginated
      ? joinSearchResults(searchResultsPaginated)
      : undefined;
    if (searchResults) {
      const searchResultsMap = searchResults.reduce(
        (obj, result) => {
          obj[`${result.chainId}-${result.address}`] = result;
          return obj;
        },
        {} as { [key: string]: any },
      );

      return getSearchComboBoxItemPropsForTokens(
        recentlyViewed.filter((token: TokenResult) => {
          if (selectedChainIdFilter && selectedChainIdFilter !== token.chainId) return false;
          return !!searchResultsMap[`${token.chainId}-${token.address}`];
        }),
      );
    }

    // return recent results filtered by the selected chain Id filter, if there is one.
    return getSearchComboBoxItemPropsForTokens(
      recentlyViewed.filter((token: TokenResult) => {
        if (selectedChainIdFilter && selectedChainIdFilter !== token.chainId) return false;
        return true;
      }),
    );
  }, [recentlyViewed, searchResultsPaginated, selectedChainIdFilter]);

  const trendingTokensItems = useMemo(() => {
    const tokens = shouldShowAllTrendingTokens
      ? trendingTokens
      : trendingTokens?.slice(0, TRENDING_TOKENS_DEFAULT_COUNT);
    return getSearchComboBoxItemPropsForTokens(tokens);
  }, [shouldShowAllTrendingTokens, trendingTokens]);

  const tokensWithBalancesItems = useMemo(() => {
    const tokens = shouldShowAllTokensWithBalances
      ? tokensWithBalances
      : tokensWithBalances?.slice(0, TOKENS_WITH_BALANCE_DEFAULT_COUNT);
    return getSearchComboBoxItemPropsForTokens(tokens);
  }, [shouldShowAllTokensWithBalances, tokensWithBalances]);

  /**
   * Takes the token combobox item key and returns a token from the list of all token results
   * @param key - Token combobox item key string
   * @returns `TokenResult` | undefined
   */
  function getTokenResultFromKey(key: Key): TokenResult | undefined {
    const [chainIdString, tokenAddress, sectionId] = key.toString().split('-') as [
      string,
      string,
      SEARCH_SECTION_ID,
    ];
    const chainId = Number(chainIdString);

    const tokenSectionsHashMap: Record<string, TokenResult[] | undefined> = {
      [SEARCH_SECTION_TYPE_BALANCES]: tokensWithBalances,
      [SEARCH_SECTION_TYPE_RESULTS]: searchResults,
      [SEARCH_SECTION_TYPE_TRENDING_TOKENS]: trendingTokens,
      [SEARCH_SECTION_TYPE_RECENTLY_VIEWED]: recentlyViewed,
    };

    return tokenSectionsHashMap[sectionId]?.find(
      (token) => token.chainId === chainId && token.address === tokenAddress,
    );
  }

  function onOpenAutoFocus(e: Event) {
    e.preventDefault();
    inputRef.current?.focus();
  }

  /**
   * Handle changing a new chain to filter results by
   * @param newChainIdFilter - Chain id to use as the new filter
   */
  function handleChangeChainFilter(newChainIdFilter: number | undefined) {
    setSelectedChainIdFilter(newChainIdFilter);
  }

  /**
   * Clear recently viewed tokens
   * @param e - Mouse click event
   */
  const handleClearRecentlyViewed = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      inputRef.current?.focus();
      clearRecentlyViewedTokens();
      e.stopPropagation();
    },
    [clearRecentlyViewedTokens],
  );

  /**
   * Handle when a user selects a token
   * @param key - key for the selected token. chainId-tokenAddress-type
   */
  function handleSelectTokenKey(key: Key | null) {
    if (!key) return;

    const [chainId, tokenAddress] = key.toString().split('-');

    // Load more buttons leverage the same dash-delineated key string to derive which collection to paginate
    if (chainId === SEARCH_SECTION_LOAD_MORE_PREFIX) {
      logEvent({
        name: EVENT_NAME.SEARCH_SHOW_MORE_TOKENS,
        properties: {
          search_type: searchType,
        },
      });

      const paginationType = tokenAddress;
      switch (paginationType) {
        case SEARCH_SECTION_TYPE_BALANCES:
          setShouldShowAllTokensWithBalances(true);
          break;
        case SEARCH_SECTION_TYPE_RESULTS:
          setSearchSize(searchSize + 1);
          break;
        case SEARCH_SECTION_TYPE_TRENDING_TOKENS:
          setShouldShowAllTrendingTokens(true);
          break;
      }

      return;
    }

    const token = getTokenResultFromKey(key);
    if (!token) {
      console.warn(`Could not find token info for key ${key}`);
      return;
    }

    logEvent({
      name: EVENT_NAME.SEARCH_TOKEN_SYMBOL_SELECTED,
      properties: {
        selected_token_address: tokenAddress,
        selected_token_chain: NETWORK_NAME_FOR_URLPATH_PER_CHAIN_ID[Number(chainId)],
        input_query: debouncedInputValue,
        search_results: searchResults,
        search_type: searchType,
      },
    });

    handleSelectTokenResult(token);
  }

  function handleSelectTokenResult(token: TokenResult) {
    // Add token to list of recently viewed tokens
    addRecentlyViewedToken(token);
    // Callback
    onSelect(token);
  }

  useEffect(() => {
    if (chainId) setSelectedChainIdFilter(chainId);
  }, [chainId]);

  const paginatedResults = searchResultsPaginated || defaultPaginatedResults;

  const hasError =
    (debouncedInputValue.length > 0 && !!searchError) ||
    (debouncedInputValue.length === 0 && !!trendingResultsError);
  const shouldHandleNoResults =
    !isLoadingSearchResults && searchResults?.length === 0 && debouncedInputValue.length > 0;
  const hasMoreSearchResults =
    !!searchResults &&
    paginatedResults.length > 0 &&
    'resultCount' in paginatedResults[0] &&
    searchResults.length < paginatedResults[0].resultCount;

  const hasMoreTokensWithBalances =
    !shouldShowAllTokensWithBalances &&
    !!tokensWithBalances &&
    tokensWithBalances.length > TOKENS_WITH_BALANCE_DEFAULT_COUNT;
  const hasMoreTrendingTokens =
    !shouldShowAllTrendingTokens &&
    !!trendingTokens &&
    trendingTokens.length > TRENDING_TOKENS_DEFAULT_COUNT;
  const isLoadingMoreSearchResults = !isLoadingSearchResults && isValidatingSearch;

  return (
    <Dialog.Content
      className={contentContainerClass}
      id="token-select"
      forceMount
      onOpenAutoFocus={onOpenAutoFocus}
    >
      <div className={dialogClass}>
        <VisuallyHidden>
          <Dialog.Title className={fontStyles.H5}>Search tokens</Dialog.Title>
        </VisuallyHidden>

        <Dialog.Close className={dialogCloseBtnClass}>
          <XIcon aria-hidden className={dialogCloseBtnIconClass} />
        </Dialog.Close>

        <SearchIcon className={searchIconClass} />

        <TokenSelectModalComboBox
          chainId={selectedChainIdFilter}
          inputValue={inputValue}
          isLoading={isLoadingTrendingResults}
          isSearching={isLoadingSearchResults}
          error={hasError}
          label="Search for a token"
          menuTrigger="focus"
          noResults={shouldHandleNoResults}
          onChangeChainFilter={handleChangeChainFilter}
          onInputChange={(searchTerm) => setInputValue(searchTerm)}
          onSelectionChange={handleSelectTokenKey}
          suggestedTokensChainId={suggestedTokensChainId}
          showSuggestedTokensNetworkName={selectedChainIdFilter === undefined}
          onSelectTokenResult={handleSelectTokenResult}
          placeholder="Search token name or paste address"
          ref={inputRef}
          selectedKey={null}
          supportedChains={supportedChains}
        >
          {getSections({
            hasMoreSearchResults,
            hasMoreTrendingTokens,
            hasMoreTokensWithBalances,
            isLoadingMoreSearchResults,
            onClearRecent: handleClearRecentlyViewed,
            recentResults: recentResultsItems,
            searchResults: searchResultsItems,
            trendingTokens: trendingTokensItems,
            tokensWithBalances: tokensWithBalancesItems,
          })}
        </TokenSelectModalComboBox>
      </div>
    </Dialog.Content>
  );
};

interface TokenSelectModalProps {
  /** The chain ID to filter tokens by */
  chainId?: number;
  /** The component that triggers the token select modal */
  children: ReactNode;
  /** Callback when a token is selected */
  onSelect: (token: TokenResult) => void;
  /** The active token */
  activeToken?: TokenResult;
  /** Component that triggered the token select modal */
  searchType: SEARCH_TYPES;
  /** List of supported chains */
  supportedChains?: Chain[];
}

const TokenSelectModal = ({
  chainId,
  children,
  onSelect,
  activeToken,
  searchType,
  supportedChains,
}: TokenSelectModalProps) => {
  // hooks
  const overlayRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  // event handlers
  const handleSelect = (value: TokenResult) => {
    onSelect(value);
    setOpen(false);
  };

  // render
  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay ref={overlayRef} className={dialogOverlayClass}>
          <Content
            activeToken={activeToken}
            chainId={chainId}
            onSelect={handleSelect}
            supportedChains={supportedChains}
            searchType={searchType}
          />
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default TokenSelectModal;
